/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { AppState } from 'redux/store';

export interface CorporateState {
  corporateId: string;
  tabActive: number;
  companyName: string;
  statusCorporate: string;
}

const initialState: CorporateState = {
  corporateId: '',
  tabActive: 1,
  companyName: '',
  statusCorporate: '',
};

export const corporateSlice = createSlice({
  name: 'corporate',
  initialState,
  reducers: {
    setCorporateIdState(state, action) {
      state.corporateId = action.payload;
    },
    setTabActive(state, action) {
      state.tabActive = action.payload;
    },
    setCompanyNameCorporate(state, action) {
      state.companyName = action.payload;
    },
    setStatusCorporate(state, action) {
      state.statusCorporate = action.payload;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.corporate,
    }),
  },
});

export const { setCorporateIdState, setTabActive, setCompanyNameCorporate, setStatusCorporate } =
  corporateSlice.actions;

export const getCorporateId = (state: AppState): string => state.corporate.corporateId;
export const getTabActive = (state: AppState): number => state.corporate.tabActive;
export const getCorporateCompanyName = (state: AppState): string => state.corporate.companyName;
export const getStatusCorporate = (state: AppState): string => state.corporate.statusCorporate;

export default corporateSlice.reducer;
