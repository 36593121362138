/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { AppState } from 'redux/store';

// Type for our state
export interface OmnibusState {
  companyName: string;
  companyId: string;
  currentTabCreateOmnibus: number;
  reloadButton: number;
  statusOmnibus: string;
}

// Initial state
const initialState: OmnibusState = {
  companyName: '',
  companyId: null,
  currentTabCreateOmnibus: 1,
  reloadButton: 0,
  statusOmnibus: '',
};

// Actual Slice
export const omnibusSlice = createSlice({
  name: 'omnibus',
  initialState,
  reducers: {
    // Action to set the authentication status
    setCompanyNameState(state, action) {
      state.companyName = action.payload;
    },
    setCompanyIdState(state, action) {
      state.companyId = action.payload;
    },
    setCurrentTabCreateOmnibusState(state, action) {
      state.currentTabCreateOmnibus = action.payload;
    },
    setReloadButtonState(state, action) {
      state.reloadButton = action.payload;
    },
    setStatusOmnibusState(state, action) {
      state.statusOmnibus = action.payload;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.omnibus,
    }),
  },
});

export const {
  setCompanyNameState,
  setCompanyIdState,
  setCurrentTabCreateOmnibusState,
  setReloadButtonState,
  setStatusOmnibusState,
} = omnibusSlice.actions;

export const selectCompanyNameOmnibusState = (state: AppState): string => state.omnibus.companyName;
export const selectCompanyIdOmnibusState = (state: AppState): string => state.omnibus.companyId;
export const selectCurrentTabOmnibusState = (state: AppState): number => state.omnibus.currentTabCreateOmnibus;
export const selectReloadButtonState = (state: AppState): number => state.omnibus.reloadButton;
export const selectStatusOmnibusState = (state: AppState): string => state.omnibus.statusOmnibus;

export default omnibusSlice.reducer;
