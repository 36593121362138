/* eslint-disable no-underscore-dangle */
import '@aws-amplify/ui/dist/style.css';
import 'gridjs/dist/theme/mermaid.css';
import { Grommet, ThemeContext } from 'grommet';
import dynamic from 'next/dynamic';
import Router from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import 'prismjs/themes/prism.css';
import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import { reduxWrapper } from 'redux/store';
import { IoProvider } from 'socket.io-react-hook';
import { Network } from 'state/network';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
// import 'styles/custom.css';
// eslint-disable-next-line import/no-unresolved
import 'styles/index.css';
import { SWRConfig } from 'swr';
import { getTheme } from 'utils/common';

const myTheme = getTheme(String('BRAND1') as any);

const MqttProvider = dynamic(() => import('providers/mqtt-provider'), {
  ssr: false,
});

Router.events.on('routeChangeStart', (url) => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const theme = {
  global: {
    colors: {
      brand: myTheme.brandColor,
    },
    font: {
      size: '14px',
      height: '20px',
    },
  },
  select: {
    icons: {
      color: '#e2e8f0',
      margin: '10px',
    },
  },
  tab: {
    active: {
      background: 'transparent',
      color: '#424242',
    },
    background: 'transparent',
    border: {
      color: 'transparent',
      active: {
        color: {
          light: myTheme.brandColor,
        },
      },
      hover: {
        color: {
          light: myTheme.brandColor,
        },
      },
    },
    color: '#828282',
    hover: {
      background: 'transparent',
      color: '#424242',
    },
    margin: {
      horizontal: 'small',
      vertical: 'none',
    },
    pad: 'small',
  },
};

function useMyLogger(useSWRNext) {
  return (key, fetcher, config) => {
    // Add logger to the original fetcher.
    const extendedFetcher = (...args) => {
      return fetcher(...args);
    };

    // Execute the hook with the new fetcher.
    const swr = useSWRNext(key, extendedFetcher, config);

    useEffect(() => {
      if (swr.data) {
      }
    }, [key, swr.data]);

    return swr;
  };
}

const GlobalStyle = createGlobalStyle`
/* Webfont: Lato-Black */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-Black.eot'); /* IE9 Compat Modes */
  src: url('./Lato-Black.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-Black.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-Black.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-Black.ttf') format('truetype');
  /* font-style: normal; */
  font-weight: 900;
  font-style: normal;
}

/* Webfont: Lato-BlackItalic */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-BlackItalic.eot'); /* IE9 Compat Modes */
  src: url('./Lato-BlackItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-BlackItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-BlackItalic.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

/* Webfont: Lato-Bold */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-Bold.eot'); /* IE9 Compat Modes */
  src: url('./Lato-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Lato-Bold.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-Bold.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-Bold.ttf') format('truetype');
  /* font-style: normal; */
  font-weight: bold;
  font-style: normal;
}

/* Webfont: Lato-BoldItalic */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-BoldItalic.eot'); /* IE9 Compat Modes */
  src: url('./Lato-BoldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-BoldItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-BoldItalic.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

/* Webfont: Lato-Hairline */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-Hairline.eot'); /* IE9 Compat Modes */
  src: url('./Lato-Hairline.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-Hairline.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-Hairline.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-Hairline.ttf') format('truetype');
  /* font-style: normal; */
  font-weight: normal;
}

/* Webfont: Lato-HairlineItalic */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-HairlineItalic.eot'); /* IE9 Compat Modes */
  src: url('./Lato-HairlineItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-HairlineItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-HairlineItalic.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-HairlineItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

/* Webfont: Lato-Heavy */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-Heavy.eot'); /* IE9 Compat Modes */
  src: url('./Lato-Heavy.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-Heavy.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-Heavy.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-Heavy.ttf') format('truetype');
  /* font-style: normal; */
  font-weight: normal;
}

/* Webfont: Lato-HeavyItalic */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-HeavyItalic.eot'); /* IE9 Compat Modes */
  src: url('./Lato-HeavyItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-HeavyItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-HeavyItalic.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-HeavyItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

/* Webfont: Lato-Italic */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-Italic.eot'); /* IE9 Compat Modes */
  src: url('./Lato-Italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-Italic.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-Italic.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

/* Webfont: Lato-Light */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-Light.eot'); /* IE9 Compat Modes */
  src: url('./Lato-Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-Light.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-Light.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-Light.ttf') format('truetype');
  /* font-style: normal; */
  font-weight: 300;
  font-style: normal;
}

/* Webfont: Lato-LightItalic */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-LightItalic.eot'); /* IE9 Compat Modes */
  src: url('./Lato-LightItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-LightItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-LightItalic.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* Webfont: Lato-Medium */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-Medium.eot'); /* IE9 Compat Modes */
  src: url('./Lato-Medium.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-Medium.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-Medium.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-Medium.ttf') format('truetype');
  /* font-style: normal; */
  font-weight: 500;
  font-style: normal;
}

/* Webfont: Lato-MediumItalic */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-MediumItalic.eot'); /* IE9 Compat Modes */
  src: url('./Lato-MediumItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-MediumItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-MediumItalic.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

/* Webfont: Lato-Regular */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('./Lato-Regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-Regular.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-Regular.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-Regular.ttf') format('truetype');
  /* font-style: normal; */
  font-weight: normal;
  font-style: normal;
}

/* Webfont: Lato-Semibold */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-Semibold.eot'); /* IE9 Compat Modes */
  src: url('./Lato-Semibold.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-Semibold.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-Semibold.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-Semibold.ttf') format('truetype');
  /* font-style: normal; */
  font-weight: bold;
}

/* Webfont: Lato-SemiboldItalic */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-SemiboldItalic.eot'); /* IE9 Compat Modes */
  src: url('./Lato-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-SemiboldItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-SemiboldItalic.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-SemiboldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

/* Webfont: Lato-Thin */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-Thin.eot'); /* IE9 Compat Modes */
  src: url('./Lato-Thin.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Lato-Thin.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-Thin.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-Thin.ttf') format('truetype');
  /* font-style: normal; */
  font-weight: 100;
  font-style: normal;
}

/* Webfont: Lato-ThinItalic */
@font-face {
  font-family: 'Lato';
  src: url('./Lato-ThinItalic.eot'); /* IE9 Compat Modes */
  src: url('./Lato-ThinItalic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./Lato-ThinItalic.woff2') format('woff2'),
    /* Modern Browsers */ url('./Lato-ThinItalic.woff') format('woff'),
    /* Modern Browsers */ url('./Lato-ThinItalic.ttf') format('truetype');
    font-weight: 100;
  font-style: italic;
}

`;

function App({ Component, token, pageProps }) {
  // const apolloClient = useApollo(pageProps.initialApolloState);
  const store: any = useStore();

  return (
    <Grommet theme={theme} full>
      <ThemeProvider theme={myTheme}>
        <ThemeContext.Extend value={{ spacing: 16 }}>
          {/* <IoProvider> */}
          <Network.Provider initialState={token}>
            {/* <ApolloProvider client={apolloClient}> */}
            <SWRConfig
              value={{
                use: process.env.NODE_ENV === 'development' ? [useMyLogger] : [],
                revalidateOnFocus: false,
              }}
            >
              <GlobalStyle />
              <PersistGate persistor={store.__persistor} loading={<div>Loading</div>}>
                <MqttProvider>
                  <Component {...pageProps} />
                </MqttProvider>
              </PersistGate>
            </SWRConfig>
            {/* </ApolloProvider> */}
            <ToastContainer style={{ fontSize: 18 }} autoClose={8000} newestOnTop />
          </Network.Provider>
          {/* </IoProvider> */}
        </ThemeContext.Extend>
      </ThemeProvider>
    </Grommet>
  );
}

export default reduxWrapper.withRedux(App);
