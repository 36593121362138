/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { AppState } from 'redux/store';

export interface IssuerState {
  issuerId: string;
  tabActive: number;
  companyName: string;
  statusIssuer: string;
}

const initialState: IssuerState = {
  issuerId: '',
  tabActive: 1,
  companyName: '',
  statusIssuer: '',
};

export const issuerSlice = createSlice({
  name: 'issuer',
  initialState,
  reducers: {
    setIssuerIdState(state, action) {
      state.issuerId = action.payload;
    },
    setTabActive(state, action) {
      state.tabActive = action.payload;
    },
    setCompanyNameIssuer(state, action) {
      state.companyName = action.payload;
    },
    setStatusIssuer(state, action) {
      state.statusIssuer = action.payload;
    },
  },
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.issuer,
    }),
  },
});

export const { setIssuerIdState, setTabActive, setCompanyNameIssuer, setStatusIssuer } = issuerSlice.actions;

export const getIssuerId = (state: AppState): string => state.issuer.issuerId;
export const getTabActive = (state: AppState): number => state.issuer.tabActive;
export const getIssuerCompanyName = (state: AppState): string => state.issuer.companyName;
export const getStatusIssuer = (state: AppState): string => state.issuer.statusIssuer;

export default issuerSlice.reducer;
