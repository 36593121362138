/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';
import { AppState } from 'redux/store';

interface MetaData {
  id: number;
  code: string;
  label: string;
  parentId: number;
  companyId: number;
  companyName: string;
}

interface CoporateMetadata {
  annualAnticipatedInvestmentAmount: MetaData[];
  annualRevenue: MetaData[];
  corporateEntityTypes: MetaData[];
  countries: MetaData[];
  designation: MetaData[];
  industries: MetaData[];
  investmentDuration: MetaData[];
  investmentObjective: MetaData[];
  investorTypes: MetaData[];
  relationshipWithPEP: MetaData[];
  shareholderType: MetaData[];
  sourceOfFundsWealth: MetaData[];
  totalNetAssets: MetaData[];
  typeOfDirectors: MetaData[];
}

interface MetadataV2 {
  countries: MetaData[];
  amlKycStatus: MetaData[];
  amlRisk: MetaData[];
  prefixPhones: MetaData[];
  corporateAmlStatus: MetaData[];
}

// Type for our state
export interface MetadataState {
  metadataV2: MetadataV2;
  coporateMetadata: CoporateMetadata;
}

// Initial state
const initialState: MetadataState = {
  metadataV2: null,
  coporateMetadata: null,
};

// Actual Slice
export const metadataSlice = createSlice({
  name: 'metadata',
  initialState,
  reducers: {
    // Action to set the authentication status
    setMetadataV2(state, action) {
      state.metadataV2 = action.payload;
    },
    setcoporateMetadata(state, action) {
      state.coporateMetadata = action.payload;
    },
  },

  // Special reducer for hydrating the state. Special case for next-redux-wrapper
  extraReducers: {
    [HYDRATE]: (state, action) => ({
      ...state,
      ...action.payload.omnibus,
    }),
  },
});

export const { setMetadataV2, setcoporateMetadata } = metadataSlice.actions;

export const selectMetadataV2State = (state: AppState): MetadataV2 => state.metadata.metadataV2;
export const selectCoporateMetadataState = (state: AppState): CoporateMetadata => state.metadata.coporateMetadata;

export default metadataSlice.reducer;
