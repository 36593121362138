/* eslint-disable no-underscore-dangle */
import { corporateSlice } from './slice/corporateSlice';
import { issuerSlice } from './slice/issuerSlice';
import { metadataSlice } from './slice/metaDataSlice';
import { omnibusSlice } from './slice/omnibusSlice';
import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const rootReducer = combineReducers({
  omnibus: omnibusSlice.reducer,
  metadata: metadataSlice.reducer,
  issuer: issuerSlice.reducer,
  corporate: corporateSlice.reducer,
});

const makeConfiguredStore = () =>
  configureStore({
    reducer: rootReducer,
    devTools: true,
  });

export const makeStore = () => {
  const isServer = typeof window === 'undefined';
  if (isServer) {
    return makeConfiguredStore();
  }
  // we need it only on client side
  const persistConfig = {
    key: 'nextjs',
    whitelist: ['omnibus', 'metadata', 'issuer', 'corporate'], // make sure it does not clash with server keys
    storage,
  };
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  const store: any = configureStore({
    reducer: persistedReducer,
    devTools: true,
  });
  store.__persistor = persistStore(store); // Nasty hack
  return store;
};

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

export const reduxWrapper = createWrapper<AppStore>(makeStore);
